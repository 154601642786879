import React from "react"
import styled from "styled-components"
import hexToRgba from "../../utils/hexToRgba"

// Assets
import productionIcon from "../../images/icons/briefcase.svg"
import certificateIcon from "../../images/icons/file-dark.svg"
import experienceIcon from "../../images/icons/book.svg"

// Styled
import Icon from "../styled/icon"
import { Section, Line } from "../styled/layout"
import { Title, Subtitle, Text } from "../styled/typography"
import { Container, Row, Col, media } from "styled-bootstrap-grid"

const ProsWrapper = styled(Section)`
  padding: 40px 0;
`
const ProsHeader = styled.header`
  margin-bottom: 2.5rem;
`
const ProsCol = styled(Col)`
  margin-bottom: 2rem;
  ${media.md`
    margin-bottom: 0rem
  `};
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  height: 100%;
  padding: 3rem 1.5rem 2rem 1.5rem;

  box-shadow: 0px 4px 20px ${({ theme }) => hexToRgba(theme.colors.dark, 0.1)};

  ${media.lg`
    padding: 3rem 2rem 2rem 2rem;
  `};
`

const CardLine = styled(Line)`
  margin: 1.45rem 0;
`

const CardIcon = styled(Icon)`
  margin-bottom: 0.5rem;
`

const ProsPage = ({ ...props }) => {
  return (
    <ProsWrapper {...props}>
      <Container>
        <ProsHeader>
          <Title uppercase align="center">
            Dlaczego my?
          </Title>
          <Line center width="calc(8% + 40px)"></Line>
        </ProsHeader>
        <Row>
          <ProsCol
            md="4"
            data-sal="fade"
            data-sal-easing="ease-in"
            data-sal-duration="200"
          >
            <Card>
              <CardIcon src={productionIcon} huge></CardIcon>
              <CardLine width="45%"></CardLine>
              <Subtitle>Produkcja oraz montaż ogrodzeń</Subtitle>
            </Card>
          </ProsCol>
          <ProsCol
            md="4"
            data-sal="fade"
            data-sal-easing="ease-in"
            data-sal-duration="200"
          >
            <Card>
              <CardIcon src={certificateIcon} huge></CardIcon>
              <CardLine width="45%"></CardLine>
              <Subtitle>Jako jedyni w regionie posiadamy certyfikat</Subtitle>
            </Card>
          </ProsCol>
          <ProsCol
            md="4"
            data-sal="fade"
            data-sal-easing="ease-in"
            data-sal-duration="200"
          >
            <Card>
              <CardIcon src={experienceIcon} huge></CardIcon>
              <CardLine width="45%"></CardLine>
              <Subtitle>Posiadamy wieloletnie doświadczenie</Subtitle>
            </Card>
          </ProsCol>
        </Row>
      </Container>
    </ProsWrapper>
  )
}

export default ProsPage
