import React from "react"
import { graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"

// Components
import Layout from "../layout/layout"
import Header from "../layout/header"
import SEO from "../layout/seo"
import About from "../components/homepage/about"
import Pros from "../components/homepage/pros"
import Contact from "../components/homepage/contact"
import Offer from "../components/homepage/offer"
import Opinions from "../components/homepage/opinions"

const GlobalStyles = createGlobalStyle`
  html {
      scroll-behavior: smooth;
  }
`

const IndexPage = ({ data }) => {
  //  console.log(data)

  const slides = data.graphcms.header.slides?.map(el => ({
    title: el.title,
    subtitle: el.subtitle,
    image: el.image?.imageFile?.childImageSharp.fluid,
    imageAlt: el.image?.alt ?? el.title + el.subtitle,
    backgroundOpacity: el.darken / 100 ?? 0.45,
  }))

  const offers = [
    {
      image: data.beton?.childImageSharp.fluid,
      imageAlt: "Ogrodzenie betonowe",
      title: "Ogrodzenia betonowe",
      description:
        "Wieloletnie doświadczenie przekłada się na jakość wykonywanych produktów z betonu.",
      link: "ogrodzenia-betonowe",
    },
    {
      image: data.panel?.childImageSharp.fluid,
      imageAlt: "Ogrodzenie panelowe",
      title: "Ogrodzenia panelowe",
      description:
        "Sprawdzony system ogrodzeń panelowych w dowolnych wersjach oraz rozmiarach.",
      link: "/ogrodzenia-panelowe",
    },
    {
      image: data.bramy?.childImageSharp.fluid,
      imageAlt: "Brama i furtka",
      title: "Bramy i furtki",
      description:
        "Produkcja, sprzedaż, oraz montaż nowoczesnych ogrodzeń, bram przesuwnych, skrzydłowych oraz furtek.",
      link: "/bramy-i-furtki",
    },
  ]

  const opinions =
    data.graphcms?.testimonials?.map(el => ({
      text: el.text,
      person: el.name,
      key: el.id,
    })) ?? []

  return (
    <Layout>
      <GlobalStyles />
      <SEO title="Ogrodzenia panelowe i betonowe | Kępno, Mroczeń"/>
      <Header darkBackgroundOpacity={0.05} slides={slides} />
      <Pros />
      <About
        id="o-nas"
        content={data.graphcms?.about.text.html}
        image={data.graphcms?.about.image?.imageFile?.childImageSharp.fluid}
        imageAlt={"ALT"}
      />
      <Offer offers={offers} id="oferta" />
      {opinions.length > 0 && <Opinions opinions={opinions} />}
      <Contact id="kontakt" />
    </Layout>
  )
}

export const query = graphql`
  query {
    beton: file(relativePath: { eq: "beton.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bramy: file(relativePath: { eq: "bramy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    panel: file(relativePath: { eq: "panel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    graphcms {
      header(where: { id: "ckl6lmz6o0wjb0b46c9m867oi" }) {
        slides {
          title
          subtitle
          darken
          image {
            url
            alt
            imageFile {
              childImageSharp {
                fluid(
                  maxWidth: 2560
                  srcSetBreakpoints: [1920, 1440, 1200, 1024]
                  quality: 90
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      about(where: { id: "cklkxmmy80ic20a139ndfydzf" }) {
        image {
          url
          alt
          imageFile {
            childImageSharp {
              fluid(
                maxWidth: 1000
                quality: 90
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        text {
          html
        }
      }
      testimonials {
        text
        name
        id
      }
    }
  }
`

export default IndexPage
