import React, { useRef } from "react"
import styled from "styled-components"

// Components
import Swiper from "react-id-swiper"

// Styled
import { SwiperStyles, Section, Line, Center } from "../styled/layout"
import { Title, Subtitle } from "../styled/typography"
import { Container, media } from "styled-bootstrap-grid"

const OpinionsWrapper = styled(Section)`
  background: ${({ theme }) => theme.colors.light};
  padding: 40px 0;
  ${media.sm`
    padding: 80px 0;
  `}
`

const OpinionsHeader = styled.header`
  margin-bottom: 1.5rem;
  margin-top: 1.75rem;
`

const OpinionWrapper = styled(Center).attrs(props => ({
  as: "figure",
  column: true,
  ...props,
}))`
  margin: 0;
`

const OpinionText = styled(Subtitle).attrs(props => ({
  as: "blockquote",
  ...props,
}))`
  margin: 0;
  margin-bottom: 1rem;
  padding: 0 3rem;

  font-style: italic;
  font-weight: 400;
  text-align: center;
  font-size: 1.2rem;

  @media (max-width: 500px) {
    font-size: 18px;
  }

  &::before {
    content: '"';
  }
  &::after {
    content: '"';
  }
`

const OpinionsPage = ({ opinions, ...props }) => {
  const swiperRef = useRef(null)
  const swiperOptions = {
    slidesPerView: 1,
    loop: true,
    grabCursor: true,
    // effect: "fade",

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  const swipes = opinions.map(el => (
    <OpinionWrapper key={el.key}>
      <OpinionText>{el.text}</OpinionText>
      <figcaption>
        <Subtitle as="cite">{el.person}</Subtitle>
      </figcaption>
    </OpinionWrapper>
  ))

  if (opinions.length == 1) {
    return (
      <OpinionsWrapper {...props}>
        <Container>
          <OpinionsHeader>
            <Title uppercase align="center">
              Opinia naszych klientów
            </Title>
            <Line width="20%" center></Line>
          </OpinionsHeader>

          {swipes}
        </Container>
      </OpinionsWrapper>
    )
  }

  return (
    <OpinionsWrapper {...props}>
      <Container>
        <OpinionsHeader>
          <Title uppercase align="center">
            Opinie naszych klientów
          </Title>
          <Line width="20%" center></Line>
        </OpinionsHeader>
        <SwiperStyles />
        <Swiper ref={swiperRef} {...swiperOptions}>
          {swipes}
        </Swiper>
      </Container>
    </OpinionsWrapper>
  )
}

OpinionsPage.defaultProps = {
  opinions: [],
}

export default OpinionsPage
