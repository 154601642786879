import React from "react"
import styled from "styled-components"

// Styled
import LazyImage from "../styled/lazyImage"
import { Section, Line } from "../styled/layout"
import { Content, Title } from "../styled/typography"
import { Container, Row, Col, media } from "styled-bootstrap-grid"

const AboutWrapper = styled(Section)`
  background: ${({ theme }) => theme.colors.light};
`

const AboutHeader = styled.header`
  margin-bottom: 1.5rem;
  margin-top: 1.75rem;

  ${media.lg`
    margin-top: 0rem
  `};
`

const AboutImage = styled(LazyImage)`
  min-height: 200px;

  ${media.sm`
    min-height: 250px;
  `};

  ${media.md`
    min-height: 400px;
  `};
`

const AboutPage = ({ image, imageAlt, content, ...props }) => {
  return (
    <AboutWrapper
      {...props}
      as="div"
      data-sal="fade"
      data-sal-easing="ease"
      data-sal-duration="200"
    >
      <Container>
        <Row>
          <Col lg="6" xs="12">
            <AboutImage cover src={image} alt={imageAlt}></AboutImage>
          </Col>
          <Col lg="6" xs="12">
            <Content>
              <AboutHeader>
                <Title uppercase>O firmie</Title>
                <Line width="20%"></Line>
              </AboutHeader>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Content>
          </Col>
        </Row>
      </Container>
    </AboutWrapper>
  )
}

export default AboutPage
