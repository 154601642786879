import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import hexToRgba from "../../utils/hexToRgba"

// Styled
import LazyImage from "../styled/lazyImage"
import Button from "../styled/button"
import { Section, Line, Center } from "../styled/layout"
import { Title, Subtitle, Text } from "../styled/typography"
import { Container, Row, Col, media } from "styled-bootstrap-grid"

const OfferWrapper = styled(Section)`
  padding: 40px 0;
`
const OfferHeader = styled.header`
  margin-bottom: 2.5rem;
`
const OfferCol = styled(Col)`
  margin-bottom: 1.75rem;
`

const CardImage = styled(LazyImage)`
  transition: transform ease-in-out 150ms;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: ${({ theme, darken }) => hexToRgba(theme.colors.dark, 0.6)};
  }
`

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  text-align: center;

  min-height: 300px;
  height: 100%;
  overflow: hidden;

  box-shadow: 0px 6px 20px ${({ theme }) => hexToRgba(theme.colors.dark, 0.1)};

  transition: box-shadow ease-in-out 250ms;

  &:hover {
    box-shadow: 0px 8px 35px
      ${({ theme }) => hexToRgba(theme.colors.dark, 0.25)};

    ${CardImage} {
      transform: scale(1.02);
    }
  }
`

const CardContent = styled.div`
  position: absolute;
  bottom: 0;
  padding: 1rem 1rem;
  text-shadow: 0px 0px 3px ${({ theme }) => hexToRgba(theme.colors.dark, 0.2)};
`

const OfferPage = ({ offers, ...props }) => {
  const offersDOM = offers.map((el, index) => (
    <OfferCol md="4" key={index}>
      <Card to={el.link}>
        <CardImage src={el.image} alt={el.imageAlt} cover></CardImage>
        <CardContent>
          <Subtitle white>{el.title}</Subtitle>
          <Text white>{el.description}</Text>
        </CardContent>
      </Card>
    </OfferCol>
  ))

  return (
    <OfferWrapper {...props}>
      <Container>
        <OfferHeader>
          <Title uppercase align="center">
            Oferta
          </Title>
          <Line center width="calc(8% + 40px)"></Line>
        </OfferHeader>
        <Row>{offersDOM}</Row>
        <Center
          data-sal="fade"
          data-sal-easing="ease-in"
          data-sal-duration="200"
        >
          <Button primary to="/realizacje">
            Nasze realizacje
          </Button>
        </Center>
      </Container>
    </OfferWrapper>
  )
}

OfferPage.defaultProps = {
  offers: [],
}

export default OfferPage
