import React from "react"
import styled from "styled-components"
import hexToRgba from "../../utils/hexToRgba"

// Assets
import mailIcon from "../../images/icons/email-dark.svg"
import phoneIcon from "../../images/icons/phone-dark.svg"

// Styled
import { Container, Row, Col, media } from "styled-bootstrap-grid"
import { Margin } from "styled-components-spacing"
import { Section, Line, Hidden } from "../styled/layout"
import { InteractiveText, Text, Title, Subtitle } from "../styled/typography"
import Icon from "../styled/icon"

const ContactWrapper = styled(Section)`
  padding: 40px 0;
`

const ContactMapFallback = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 60vh;

  border: none;
  box-shadow: 0px 6px 20px ${({ theme }) => hexToRgba(theme.colors.dark, 0.15)};
`

const ContactText = styled(InteractiveText)`
  display: flex;
  align-items: center;
`

const ContactPage = ({ ...props }) => {
  return (
    <ContactWrapper
      {...props}
      data-sal="fade"
      data-sal-easing="ease"
      data-sal-duration="250"
    >
      <Container>
        <Row>
          <Col lg="4" xs="12">
            <Title>Kontakt</Title>
            <Line></Line>
            <Text>Przedsiębiorstwo wielobranżowe "KOW-BUD"</Text>
            <Text>Sławomir Kowalczyk</Text>
            <Margin vertical="4">
              <Subtitle>Adres:</Subtitle>
              <ContactText href="https://goo.gl/maps/9X7MYinx9i6YumCR8" as="a" target="_blank">
                63-611, Mroczeń 151A
              </ContactText>
            </Margin>
            <Margin vertical="4">
              <Subtitle>Godziny pracy:</Subtitle>
              <Text>Pon - Pt: 8:00 - 16:00</Text>
              <Text>Sobota: 8:00 - 12:00</Text>
            </Margin>
            <Margin vertical="4">
              <Subtitle>Kontakt:</Subtitle>
              <ContactText href="mailto:kowbud@o2.pl" as="a">
                <Margin horizontal="1" inline>
                  <Icon src={mailIcon} tiny></Icon>
                </Margin>
                kowbud@o2.pl
              </ContactText>
              <ContactText
                href="mailto:ogrodzeniabeatakowalczyk@gmail.com"
                as="a"
              >
                <Margin horizontal="1" inline>
                  <Icon src={mailIcon} tiny></Icon>
                </Margin>
                ogrodzeniabeatakowalczyk@gmail.com
              </ContactText>
              <ContactText href="tel:+48604781485" as="a">
                <Margin horizontal="1" inline>
                  <Icon src={phoneIcon} tiny></Icon>
                </Margin>
                <Hidden hiddenBelow="lg">
                  +48 604 781 485 <br /> (Ogrodzenia betonowe - Sławek)
                </Hidden>
                <Hidden hiddenAbove="lg">
                  +48 604 781 485 (Ogrodzenia betonowe - Sławek)
                </Hidden>
              </ContactText>
              <ContactText href="tel:+48784072412" as="a">
                <Margin horizontal="1" inline>
                  <Icon src={phoneIcon} tiny></Icon>
                </Margin>
                <Hidden hiddenBelow="lg">
                  +48 784 072 412
                  <br />
                  (Ogrodzenia panelowe - Beata)
                </Hidden>
                <Hidden hiddenAbove="lg">
                  +48 784 072 412 (Ogrodzenia panelowe - Beata)
                </Hidden>
              </ContactText>
            </Margin>
          </Col>
          <Col lg="8" xs="12">
            <ContactMapFallback
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59558.71031849088!2d17.907961452002652!3d51.22281692260933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8618489b03a1875e!2sKow-Bud!5e0!3m2!1sen!2spl!4v1613127230214!5m2!1sen!2spl"
              aria-hidden="false"
              tabindex="0"
            ></ContactMapFallback>
          </Col>
        </Row>
      </Container>
    </ContactWrapper>
  )
}

export default ContactPage
